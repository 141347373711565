<template>
  <div>
    <b-tabs position="is-centered" class="block">
      <b-tab-item label="Партнёры">
        <div class="columns">
          <div class="column">
            <h2 class="title is-3">Партнёры для постбеков ({{ resales.length }})</h2>
          </div>
          <div class="column">
            <b-button
              class="is-pulled-right"
              type="is-primary"
              icon-right="plus"
              @click="createResale()"
            >Добавить партнера</b-button>
          </div>
        </div>

        <!-- ТАБЛИЦА -->
        <b-table
          :data="resales"
          class="users-table"
        >
          <b-table-column label="ID" v-slot="props">
            <span>{{ props.row.id }}</span>
          </b-table-column>

          <b-table-column label="Имя" v-slot="props">
            <span>{{ props.row.name }}</span>
          </b-table-column>

          <b-table-column label="ID кампаний" v-slot="props">
            <span>{{ props.row.campaign_ids }}</span>
          </b-table-column>

          <b-table-column label="Действия" v-slot="props">

            <div>
              <b-tooltip label="Редактировать">
                <b-button class="edit_icon" @click="updateResale(props.row.id)">
                  <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                    <path d="M13.5 6.5l4 4"></path>
                  </svg>
                </b-button>
              </b-tooltip>

              <b-tooltip label="Удалить">
                <b-button
                  class="delete_icon"
                  @click="deleteResale(props.row)">
                  <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M4 7l16 0"></path>
                    <path d="M10 11l0 6"></path>
                    <path d="M14 11l0 6"></path>
                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                  </svg>
                </b-button>
              </b-tooltip>
            </div>

          </b-table-column>
        </b-table>
      </b-tab-item>
      <b-tab-item label="Постбеки">
        <div class="columns">
          <div class="column">
            <h2 class="title is-3">Постбеки</h2>
          </div>
        </div>

        <div class="columns">
          <div class="column is-6">
            <v-select
              outlined
              dense
              hide-details
              clearable
              :items="resales"
              item-value="id"
              item-text="name"
              label="Партнер"
              v-model="params.resale_id"
              @input="getPostbacks"
            />
          </div>
        </div>

        <!-- ТАБЛИЦА -->
        <b-table
          :data="postbacks.results"
          paginated
          backend-pagination
          :total="postbacks.total"
          :per-page="params.perPage"
          @page-change="onPageChange"
          class="users-table"
        >
          <b-table-column label="ID" v-slot="props">
            <span>{{ props.row.id }}</span>
          </b-table-column>

          <b-table-column label="Дата" v-slot="props">
            <span>{{ formatDate(props.row.created_at) }}</span>
          </b-table-column>

          <b-table-column v-if="!params.resale_id" label="Партнёр" v-slot="props">
            <span>
              {{resales.find(resale => resale.id === props.row.resale_integration_id)?.name || 'Не найдено' }}
            </span>
          </b-table-column>

          <b-table-column label="Тип" v-slot="props">
            <span>{{ typeLabels[props.row.type] || 'Не найдено' }}</span>
          </b-table-column>

          <b-table-column label="Статус" v-slot="props">
            <span>{{ statusLabels[props.row.status] || 'Не найдено' }}</span>
          </b-table-column>

          <b-table-column label="Постбек" v-slot="props">
            <span>{{ props.row.postback }}</span>
          </b-table-column>

          <!-- Пагинация и выбор количества элементов на странице -->
          <template slot="bottom-left">
            <div class="pagination-controls">
              <v-select
                style="width: fit-content; float: left;"
                v-model="params.perPage"
                @change="getPostbacks"
                :items="perPageOptions"
                item-text="name"
                item-value="value"
                outlined
                hide-details
                dense
              />
            </div>
          </template>
        </b-table>
      </b-tab-item>
    </b-tabs>

    <OverlayLoader :show="isLoading"></OverlayLoader>
    <ResaleIntegrationDetailsModal></ResaleIntegrationDetailsModal>
    <ConfirmDeletionIntegrationModal></ConfirmDeletionIntegrationModal>

  </div>
</template>

<script>
import ResaleIntegrationDetailsModal from '@/app/client/resale/shared/modals/resaleIntegrationDetailsModal.vue';
import ConfirmDeletionIntegrationModal from '@/app/client/resale/shared/modals/confirmDeletionResaleIntegrationModal.vue';
import OverlayLoader from "@/app/shared/components/app-loader/Loader";

import moment from 'moment';
import 'moment-timezone';

export default {
  name: "ResalesIntegrationList",
  components: {
    ResaleIntegrationDetailsModal,
    ConfirmDeletionIntegrationModal,
    OverlayLoader
  },
  data() {
    return {
      params: {
        resale_id: null,
        perPage: 20, // передаем только число
        page: 1
      },

      perPageOptions: [
        { name: "10 на странице", value: 10 },
        { name: "20 на странице", value: 20 },
        { name: "50 на странице", value: 50 },
        { name: "100 на странице", value: 100 },
      ],

      typeLabels: {
        sale: 'Продажа',
        lead: 'Лид',
        sale_revenue: 'Изменение дохода',
        reject: 'Отклонено'
      },
      statusLabels: {
        success: 'Успех',
        reject: 'Отклонено'
      },

      // временная зона пользователя
      userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
  },
  created() {
    this.$store.dispatch("GET_RESALES_INTEGRATION");
    this.getPostbacks();
  },
  computed: {
    postbacks() {
      return this.$store.getters.GET_POSTBACKS;
    },
    serverTimezone() {
      return this.postbacks.timezone;
    },
    resales() {
      return this.$store.getters.GET_RESALES_INTEGRATION;
    },
    isLoading: {
      get() {
        return this.$store.getters.IS_RESALES_INTEGRATION_LOADING;
      },
      set(value) {
        this.$store.commit("SET_RESALES_INTEGRATION_LOADING", value);
      },
    },
  },
  methods: {
    formatDate(dateString) {
      // Устанавливаем русскую локаль
      moment.locale('ru');
      const date = moment.utc(dateString).tz(this.userTimezone);

      const now = moment().tz(this.userTimezone);
      // Проверяем, является ли дата сегодняшней
      const isToday = date.isSame(now, 'day');

      if (isToday) {
        // Если дата — сегодня, отображаем только время
        return date.format('HH:mm');
      }

      // Если не сегодня, но в этом году — добавляем день и месяц
      if (date.isSame(now, 'year')) {
        return date.format('D MMM HH:mm');
      }

      // Если не этот год, добавляем и год
      return date.format('D MMM YYYY');
    },
    createResale() {
      this.$store.dispatch("RESALE_INTEGRATION_DETAILS_MODAL", true);
    },
    updateResale(resale_id) {
      this.$store.dispatch("GET_RESALE_INTEGRATION_BY_ID", resale_id).then(() => {
        this.$store.dispatch("RESALE_INTEGRATION_DETAILS_MODAL", true);
      }).catch(() => {
        this.$toast.error("Ошибка при получении данных");
      });
    },
    deleteResale(resale) {
      this.$store.commit('SET_CURRENT_RESALE_INTEGRATION', resale);
      this.$store.dispatch("CONFIRM_DELETION_RESALE_INTEGRATION_MODAL", true);
    },
    onPageChange(page) {
      this.params.page = page;
      this.getPostbacks();
    },
    getPostbacks() {
      this.$store.dispatch("GET_POSTBACKS", this.params).catch(() => {
        this.$toast.error("Ошибка при получении данных");
      });
    }
  },
};
</script>

<style>
.draggable-item {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px;
  background-color: #fff;
}
</style>
