<template>
  <v-dialog
    v-model="isOpen"
    max-width="700"
  >
    <v-card>
      <v-card-title class="headline pb-10">
        Вы точно хотите удалить выбранного партнера {{currentResale.name}}?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">Закрыть</v-btn>
        <v-btn text color="primary" @click="deleteResale()">Удалить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OverlayLoader from '@/app/shared/components/app-loader/Loader';

export default {
  name: 'confirmDeletionIntegrationModal',
  components: {
    OverlayLoader,
  },
  data() {
    return {
    };
  },
  computed: {
    isOpen: {
      get(){
        return this.$store.getters.IS_CONFIRM_DELETION_RESALE_INTEGRATION_MODAL_OPENED;
      },
      set(){
        this.$store.dispatch('CONFIRM_DELETION_RESALE_INTEGRATION_MODAL', false);
      }
    },
    currentResale: {
      get(){
        return this.$store.getters.GET_CURRENT_RESALE_INTEGRATION;
      },
      set(value){
        this.$store.commit("SET_CURRENT_RESALE_INTEGRATION", value);
      }
    },
  },
  methods: {
    close() {
      this.$store.dispatch("CURRENT_RESALE_INTEGRATION", { });
      this.$store.dispatch('CONFIRM_DELETION_RESALE_INTEGRATION_MODAL', false);
    },
    deleteResale(){
      this.$store.dispatch('DELETE_RESALE_INTEGRATION', this.currentResale.id)
        .then(() => {
          this.$toast.success('Партнер был удален');
          this.$store.dispatch("CURRENT_RESALE_INTEGRATION", { });
          this.$store.dispatch("GET_RESALES_INTEGRATION");
          this.$store.dispatch('CONFIRM_DELETION_RESALE_INTEGRATION_MODAL', false);
        })
        .catch(() => {
          this.$toast.error('Неизвестная ошибка');
        });
    }
  },
};
</script>
