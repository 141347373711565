<template>
  <v-dialog
    v-model="isOpen"
    max-width="1000"
    @input="handleClose"
  >
    <v-card>
      <v-card-title class="headline pb-10">
        <div class="columns">
          <div class="column" v-if="currentResale.name === undefined">
            <h2 class="title is-3">Создать партнера</h2>
          </div>
          <div class="column" v-if="currentResale.name !== undefined">
            <h2 class="title is-3">Изменить партнера: {{ currentResaleName }}</h2>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-layout row>
          <v-layout column>
            <b-field label="Название">
              <b-input v-model="currentResale.name"></b-input>
            </b-field>
            <b-field class="mb-6" label="ID партнера в кейтаро(через запятую)">
              <b-input v-model="currentResale.campaign_ids"></b-input>
            </b-field>

            <h2 class="title is-5 mb-1">Постбеки</h2>

            <p class="error--text">Все постбеки отправляются GET-запросом</p>

            <b-field label="При статусе лид">
              <b-input v-model="currentResale.lead_postback"></b-input>
            </b-field>
            <b-field label="При статусе продажа">
              <b-input v-model="currentResale.sale_postback"></b-input>
            </b-field>
            <b-field label="При статусе отклонено">
              <b-input v-model="currentResale.reject_postback"></b-input>
            </b-field>

            <p class="title is-5 mb-3">Пример вида постбека</p>
            <p class="subtitle-2">При лиде</p>
            <pre>
              https://domen.ru/cmq8l0k.php?cnv_id={aff_sub5}&add_event1=1
            </pre>

            <p class="subtitle-2">При продаже</p>
            <pre>
              https://domen.ru/cmq8l0k.php?cnv_id={aff_sub5}&add_event2=1&payout={revenue}
            </pre>

          </v-layout>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">Закрыть</v-btn>
        <v-btn text color="primary" v-if="currentResale.id == null" @click="createResale()">Добавить</v-btn>
        <v-btn text color="primary" v-if="currentResale.id != null" @click="updateResale()">Обновить</v-btn>
      </v-card-actions>
      <overlay-loader :show="isLoading"></overlay-loader>
    </v-card>
  </v-dialog>
</template>

<script>
import OverlayLoader from '@/app/shared/components/app-loader/Loader';

export default {
  name: 'ResaleIntegrationDetailsModal',
  components: {
    OverlayLoader,
  },
  data() {
    return {
      // Копия имени шаблона
      currentResaleName: '',
    };
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        this.currentResaleName = this.currentResale.name;
      }
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.IS_CURRENT_RESALE_INTEGRATION_LOADING;
    },
    isOpen() {
      return this.$store.getters.IS_RESALES_DETAILS_MODAL_OPENED;
    },
    currentResale: {
      get() {
        return this.$store.getters.GET_CURRENT_RESALE_INTEGRATION;
      },
      set(value) {
        this.$store.commit("SET_CURRENT_RESALE_INTEGRATION", value);
      },
    },
  },
  methods: {
    handleClose(isOpen) {
      if (!isOpen) {
        this.close();
      }
    },
    close() {
      this.$store.dispatch("CURRENT_RESALE_INTEGRATION", { });
      this.$store.dispatch('RESALE_INTEGRATION_DETAILS_MODAL', false);
    },
    createResale(){
      this.$store.dispatch('CREATE_RESALE_INTEGRATION', this.currentResale)
        .then(() => {
          this.$toast.success("Партнер успешно добавлен");
          this.$store.dispatch("CURRENT_RESALE_INTEGRATION", { });
          this.$store.dispatch("GET_RESALES_INTEGRATION");
          this.$store.dispatch('RESALE_INTEGRATION_DETAILS_MODAL', false);
        })
        .catch(() => {
          this.$toast.error("Ошибка при добавлении партнера");
        });
    },
    updateResale(){
      console.log('Изменение текущей интеграции с партнёром');
      this.$store.dispatch('UPDATE_RESALE_INTEGRATION', this.currentResale)
        .then(() => {
          this.$toast.success("Партнер успешно изменен");
          this.$store.dispatch("CURRENT_RESALE_INTEGRATION", { });
          this.$store.dispatch("GET_RESALES_INTEGRATION");
          this.$store.dispatch('RESALE_INTEGRATION_DETAILS_MODAL', false);
        })
        .catch(() => {
          this.$toast.error("Ошибка при изменении партнера");
        });
    },
  },
};
</script>
